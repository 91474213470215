<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!purchasesPayment">
      <span class="loader"></span>
    </span>
    <div v-if="purchasesPayment" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <purchasesPayment-view-global :purchasesPayment="purchasesPayment" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            purchasesPayment.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="purchasesPayment" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <purchasesPayment-view-logs :purchasesPayment="purchasesPayment" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { PAYMENT_STATUS_DRAFT } from "@/constants/payments";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import PurchasesPaymentViewGlobal from "../partials/PurchasesPaymentViewGlobal.vue";
import PurchasesPaymentViewLogs from "../partials/PurchasesPaymentViewLogs.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PurchasesPaymentViewGlobal,
    PurchasesPaymentViewLogs,
    ListFileComponent,
  },

  mixins: [requestErrorMixin],

  props: {
    purchasesPaymentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      purchasesPayment: null,
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "purchasesPayments/get",
          this.purchasesPaymentId
        );
        this.purchasesPayment =
          this.$store.getters["purchasesPayments/purchasesPayment"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewInvoice() {
      this.$router.push(this.$objectViewRoute(this.purchasesPayment.invoice));
    },
  },
};
</script>
